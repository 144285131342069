import { useSubscription } from '@apollo/client';
import { BellIcon, Cog8ToothIcon } from '@heroicons/react/24/outline';

import { cn } from '@scannable/common';
import { useGetNotificationCountQuery } from '@scannable/frontend/common';
import { NOTIFICATIONS_SUBSCRIPTION } from '@scannable/graphql-queries';

import { Button, ButtonProps } from '../../atoms';
import useTranslation from '../../hooks/useTranslation/useTranslation';
import NotificationPreferencesSidePanel from '../../side-panels/NotificationPreferencesSidePanel/NotificationPreferencesSidePanel';
import { NotificationsSidePanel } from '../../side-panels/NotificationsSidePanel/NotificationsSidePanel';
import { useSidePanel } from '../../side-panels/SidePanelContext/SidePanelContext';

export function NotificationsButton(props: ButtonProps) {
  const { t } = useTranslation();
  const { showSidePanel } = useSidePanel();
  const { data, refetch: refetchCount } = useGetNotificationCountQuery({
    fetchPolicy: 'network-only',
  });

  useSubscription(NOTIFICATIONS_SUBSCRIPTION, {
    onData() {
      refetchCount();
    },
  });

  return (
    <Button
      className="h-10 pl-2.5 pr-2.5 md:pl-2 md:pr-3"
      color={data && data?.notificationCount > 0 ? 'blue' : 'white'}
      size="md"
      {...props}
      onClick={() =>
        showSidePanel(<NotificationsSidePanel />, {
          title: 'Notifications',
          headerAction: (
            <div
              className="text-xs text-gray-500 cursor-pointer flex flex-row items-center underline mr-4"
              onClick={() =>
                showSidePanel(<NotificationPreferencesSidePanel />, {
                  title: t('settings'),
                })
              }
            >
              <Cog8ToothIcon className="mr-1 text-gray-500" height={16} />
              <span>Settings</span>
            </div>
          ),
        })
      }
    >
      <BellIcon
        className={cn(
          data && data?.notificationCount > 0
            ? 'text-blue-700'
            : 'text-gray-500'
        )}
        height={18}
      />
      {data && data?.notificationCount > 0 && (
        <span className="ml-0.5">{data?.notificationCount}</span>
      )}
      <span
        className={cn(
          'hidden md:inline',
          data && data?.notificationCount === 0 ? 'ml-1' : 'ml-1'
        )}
      >
        {t('notifications.notifications')}
      </span>
    </Button>
  );
}

export default NotificationsButton;
