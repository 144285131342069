import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { GroupType } from '@prisma/client';

import {
  getGroupPath,
  INVENTORY_ROUTE,
  ItemAction,
  Role,
} from '@scannable/common';
import { useDeleteGroupMutation } from '@scannable/frontend/common';
import { REMOVE_ITEM_FROM_GROUP } from '@scannable/graphql-queries';

import { IconType } from '../../atoms';
import { useRoleGuard } from '../../auth';
import { useTable, useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import { ConfirmationModal, EditGroupModal, useModal } from '../../modals';
import { ShareButton } from '../../molecules';
import { ActionsButton } from '../../organisms/ActionsButton/ActionsButton';
import { TableRowType } from '../../types/table.types';
import Inventory, { InventoryActionType } from '../Inventory/Inventory';

export interface GroupedItemsTableProps {
  groupId: number;
  groupName: string;
  groupType: GroupType;
  publicId: string;
  icon: IconType;
  rowActionsToHide: InventoryActionType[];
}

function backToRoute(groupType: GroupType) {
  switch (groupType) {
    case GroupType.KIT_BAG:
      return '/admin/kit-bags';
    case GroupType.STAFF:
      return '/admin/staff';
    case GroupType.LOCATION:
      return '/admin/locations';
    case GroupType.EQUIPMENT_TYPE:
      return '/admin/equipment-type';
    case GroupType.CLIENT:
      return '/admin/clients';
    case GroupType.VEHICLE:
      return '/admin/vehicles';

    default:
      return INVENTORY_ROUTE;
  }
}

export function GroupedItemsTable({
  groupId,
  groupName,
  groupType,
  publicId,
  icon,
  rowActionsToHide,
}: GroupedItemsTableProps) {
  const { showModal, hideModal } = useModal();
  const roleGuard = useRoleGuard();
  const router = useRouter();
  const { reload, clearSelectedRows } = useTable();
  const { t } = useTranslation();
  const [deleteGroup] = useDeleteGroupMutation({
    onCompleted: () => {
      hideModal();
      router.push(backToRoute(groupType));
    },
    refetchQueries: 'active',
  });

  const [removeGroupItem] = useMutation(REMOVE_ITEM_FROM_GROUP, {
    onCompleted: () => {
      reload();
      clearSelectedRows();
      hideModal();
    },
  });
  return (
    <Inventory
      tableActions={[
        {
          render: () => {
            return (
              <ActionsButton
                actions={[
                  {
                    label: t('edit'),
                    item: null,
                    display: roleGuard([Role.Admin]),
                    callback: () =>
                      showModal(
                        <EditGroupModal
                          id={Number(groupId)}
                          title={`Edit ${groupName}`}
                        />
                      ),
                  },
                  {
                    label: 'Delete',
                    item: null,
                    type: TableRowType.danger,
                    display: roleGuard([Role.Admin]),
                    callback: () =>
                      showModal(
                        <ConfirmationModal
                          title="Are you sure you want to delete?"
                          message="Any items will be removed before deletion and will remain in your inventory"
                          onConfirm={async () => {
                            await resolveMutation(
                              deleteGroup({
                                variables: {
                                  id: groupId,
                                },
                              }),
                              {
                                successMessage: 'Group deleted',
                              }
                            );
                          }}
                        />
                      ),
                  },
                ]}
              />
            );
          },
        },
        {
          render: () => {
            return (
              <ShareButton
                label="Share"
                color="info"
                className="border border-gray-300 mr-2 text-base"
                url={`${window.location.host}${getGroupPath(
                  groupType,
                  publicId
                )}`}
              />
            );
          },
        },
      ]}
      title={groupName}
      icon={icon}
      selectable
      reportTitle={`${groupName} Inspection Report`}
      rowActionsToHide={[
        ...rowActionsToHide,
        ItemAction.removeItemFromInventory,
      ]}
      additionalRowActions={[
        {
          label: 'Remove item from group',
          type: TableRowType.danger,
          display: roleGuard([Role.Admin, Role.Member]),
          callback: (row) =>
            showModal(
              <ConfirmationModal
                title="Are you sure you want to remove this item from the group?"
                buttonLabel="Remove"
                onConfirm={async () => {
                  await resolveMutation(
                    removeGroupItem({
                      variables: {
                        itemId: row.id,
                        groupId,
                      },
                    }),
                    {
                      successMessage: 'Item removed from group',
                    }
                  );
                }}
              />
            ),
        },
      ]}
      variables={[
        {
          AND: [
            {
              groupedItems: {
                some: {
                  group: {
                    is: {
                      id: {
                        equals: Number(groupId),
                      },
                    },
                  },
                },
              },
            },
          ],
        },
      ]}
      skip={!groupId}
    />
  );
}

export default GroupedItemsTable;
