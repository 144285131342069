import { useMutation, useQuery } from '@apollo/client';
import { ProductDataType } from '@prisma/client';
import { isString } from 'class-validator';
import { FormikProps, useFormik } from 'formik';

import { Product, ProductVariation } from '@scannable/common';
import { SkuScreenDataField } from '@scannable/frontend/common';
import { FormFieldOptionType } from '@scannable/frontend/types';
import {
  FIND_ALL_CERTIFICATIONS,
  UPDATE_MANY_PRODUCT_DATA,
} from '@scannable/graphql-queries';

import { Button, Text } from '../../atoms';
import { LoadingIcon } from '../../atoms/LoadingIcon/LoadingIcon';
import { useTranslation } from '../../hooks';
import { resolveMutation } from '../../lib/lib';
import {
  InputFieldGroup,
  InputWithUnits,
  ProductFileInput,
  SidePanelButtonContainer,
} from '../../molecules';
import Form from '../Form/Form';

export interface DataFieldGroupProps {
  title: string;
  id: string;
  children: React.ReactNode;
}
type DataFieldValue = {
  [key: string]: {
    unit: string;
    value: string | number[];
  };
};

export function DataFieldGroup({ title, id, children }: DataFieldGroupProps) {
  return (
    <div className="sm:flex sm:items-center">
      <div className="w-full sm:max-w-xs focus:outline-none">
        <label htmlFor={id} className="text-sm font-medium text-gray-900">
          {title}
        </label>
        {children}
      </div>
    </div>
  );
}

type ProductDataFormProps = {
  productVariationId?: number;
  productId?: number;
  dataFields: SkuScreenDataField[];
  loading?: boolean;
};
export function ProductDataForm({
  productVariationId,
  productId,
  dataFields,
  loading,
}: ProductDataFormProps) {
  const { t } = useTranslation();
  const [updateManyData] = useMutation(UPDATE_MANY_PRODUCT_DATA, {
    refetchQueries: [
      ProductVariation.SKUScreenData,
      Product.GetProductDataQuery,
    ],
  });

  const initialValues = dataFields.reduce((acc, field) => {
    if (field.key) {
      if (field.key === 'certifications') {
        return {
          ...acc,
          [`${field.key}`]: {
            value: field.certifications?.map((cert) => cert.id) ?? [],
          },
        };
      }
      return {
        ...acc,
        [`${field.key}`]: {
          value: field.value,
          unit: field.unit,
        },
      };
    }
    return acc;
  }, {});
  const formik = useFormik<DataFieldValue>({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      const data: {
        dataKey: string;
        value?: string;
        arrayValue?: number[];
      }[] = Object.entries(values).map(([key, { value, unit }]) => {
        if (key === 'certifications' && Array.isArray(value)) {
          return {
            dataKey: key,
            arrayValue: value,
          };
        }

        return {
          dataKey: key,
          value: value && isString(value) ? value : '',
          unit,
        };
      });
      await resolveMutation(
        updateManyData({
          variables: {
            data: {
              productVariationId,
              productId,
              data,
            },
          },
        }),
        {
          successMessage: 'Product data updated',
        }
      );
      actions.setSubmitting(false);
    },
  });

  return (
    <Form className="h-full" formik={formik}>
      {loading && (
        <div className="relative h-full">
          <LoadingIcon size="sm" />
        </div>
      )}
      {!loading && dataFields.length === 0 && (
        <div className="h-full px-5 mt-4">
          <Text>No data fields</Text>
        </div>
      )}
      <div className="space-y-4 px-5 mt-4">
        {dataFields
          .sort((a, b) => {
            // Set priority order for types
            const order = [
              ProductDataType.IMAGE,
              ProductDataType.PDF,
              ProductDataType.CERTIFICATION,
              ProductDataType.PRINTER_ICON,
              ProductDataType.URL,
              ProductDataType.VIDEO_EMBED,
              ProductDataType.STRING,
              ProductDataType.TEXT,
              ProductDataType.NUMERIC,
              ProductDataType.DATE,
              ProductDataType.INSPECTION,
            ];
            if (!a.productDataType || !b.productDataType) {
              return 0;
            }

            // Determine if types are in the predefined order list
            const typeIndexA = order.indexOf(a.productDataType);
            const typeIndexB = order.indexOf(b.productDataType);

            // If both types are in the predefined order list, sort by their index
            if (typeIndexA !== -1 && typeIndexB !== -1) {
              return typeIndexA - typeIndexB;
            }

            // If only one type is in the predefined order list, that type should come first
            if (typeIndexA !== -1) {
              return -1;
            }
            if (typeIndexB !== -1) {
              return 1;
            }

            // If neither type is in the predefined order list, sort by the `order` property
            const orderA =
              a.order !== undefined && a.order !== null
                ? a.order
                : Number.MAX_SAFE_INTEGER;
            const orderB =
              b.order !== undefined && b.order !== null
                ? b.order
                : Number.MAX_SAFE_INTEGER;

            return orderA - orderB;
          })
          .map(({ key, ...rest }) => {
            if (key) {
              return (
                <DataFieldInput
                  formik={formik}
                  key={key}
                  productVariationId={productVariationId}
                  productId={productId}
                  dataKey={key}
                  {...rest}
                />
              );
            }
            return null;
          })}
      </div>
      <SidePanelButtonContainer>
        <Button
          type="submit"
          className="w-full"
          disabled={!formik.isValid || formik.isSubmitting}
          loading={formik.isSubmitting}
        >
          {t('update')}
        </Button>
      </SidePanelButtonContainer>
    </Form>
  );
}

export default ProductDataForm;

type DataFieldInputProps = {
  formik: FormikProps<DataFieldValue>;
  productVariationId?: number;
  productId?: number;
  dataKey: string;
} & Omit<SkuScreenDataField, 'key'>;
function DataFieldInput({
  formik,
  productDataType,
  dataKey,
  name,
  dataId,
  images,
  metadataId,
  pdfs,
  productVariationId,
  productId,
  availableUnits,
  dataType,
}: DataFieldInputProps) {
  const { data: certificationData } = useQuery(FIND_ALL_CERTIFICATIONS, {
    skip: productDataType !== ProductDataType.CERTIFICATION,
  });

  const certSelectOptions: FormFieldOptionType[] =
    certificationData?.certifications?.map(
      (cert: { id: number; name: string }) => ({
        label: cert.name,
        value: cert.id,
      })
    ) ?? [];

  const fieldDataType = productDataType;
  const title = name;
  const fieldName = `${dataKey}`;

  const id = `DataField-${dataId}`;
  const rawValue = formik.values[fieldName]?.value;
  const value = isString(rawValue) ? rawValue : '';
  const unit = formik.values[fieldName]?.unit ?? '';
  // for fields like Date, that only accept a 'value'
  const valueOnlyField = `${fieldName}[value]`;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(valueOnlyField, e.target.value);
  };

  if (fieldDataType === ProductDataType.CERTIFICATION) {
    return (
      <DataFieldGroup id={id} title={title}>
        <InputFieldGroup
          id="Certifications"
          name={fieldName}
          type="select"
          onChange={(value: unknown) => {
            if (Array.isArray(value)) {
              formik.setFieldValue(
                valueOnlyField,
                value.map((v) => v.value)
              );
              return;
            }
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error we need to not accept number[] to selects
          value={rawValue}
          options={certSelectOptions}
          config={{
            isMulti: true,
          }}
        />
      </DataFieldGroup>
    );
  }
  if (fieldDataType === ProductDataType.IMAGE) {
    return (
      <ProductFileInput
        title={title}
        name={fieldName}
        existingFiles={images ?? []}
        dataId={dataId}
        metadataId={metadataId}
        productVariationId={productVariationId}
        productId={productId}
        dataType={dataType}
      />
    );
  }
  if (fieldDataType === ProductDataType.PDF) {
    return (
      <ProductFileInput
        title={title}
        name={fieldName}
        existingFiles={pdfs ?? []}
        type="pdf"
        dataId={dataId}
        metadataId={metadataId}
        productVariationId={productVariationId}
        productId={productId}
        dataType={dataType}
      />
    );
  }
  if (fieldDataType === ProductDataType.DATE) {
    return (
      <DataFieldGroup id={id} title={title}>
        <InputFieldGroup
          formik={formik}
          id={id}
          type="date"
          name={valueOnlyField}
          value={value}
          config={{
            showMonthDropdown: true,
            showYearDropdown: true,
          }}
        />
      </DataFieldGroup>
    );
  }

  if (fieldDataType === ProductDataType.TEXT) {
    return (
      <DataFieldGroup id={id} title={title}>
        <InputFieldGroup
          formik={formik}
          type="textarea"
          onChange={onChange}
          name={fieldName}
          value={value}
        />
      </DataFieldGroup>
    );
  }
  if (fieldDataType === ProductDataType.VIDEO_EMBED) {
    return (
      <DataFieldGroup id={id} title={title}>
        <InputFieldGroup
          formik={formik}
          type="textarea"
          onChange={onChange}
          name={fieldName}
          value={value}
        />
      </DataFieldGroup>
    );
  }

  return (
    <DataFieldGroup id={id} title={title}>
      <InputWithUnits
        id={id}
        name={fieldName}
        type="text"
        value={value}
        formik={formik}
        unit={unit}
        availableUnits={availableUnits ?? []}
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      />
    </DataFieldGroup>
  );
}
