import { ChangeEvent, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { DocumentTextIcon, MinusCircleIcon } from '@heroicons/react/20/solid';

import { DataType, imageFromS3Key } from '@scannable/common';
import { ProductImageFile, ProductPdfFile } from '@scannable/frontend/common';
import {
  CREATE_PRODUCT_OR_VARIATION_METADATA_FILE,
  CREATE_PRODUCT_OR_VARIATION_METADATA_PHOTO,
  DELETE_PRODUCT_OR_VARIATION_METADATA_FILE,
} from '@scannable/graphql-queries';

import { Button, Image, Text } from '../../atoms';
import { resolveMutation } from '../../lib/lib';
import { Upload } from '../../molecules/Upload/Upload';
import { errorAlert } from '../../utils';

// https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
export function ProductFileInput({
  title,
  name,
  existingFiles,
  dataId,
  productVariationId,
  productId,
  metadataId,
  maxFiles,
  type = 'image',
  uploading,
  dataType,
}: {
  title?: string;
  name: string | null;
  existingFiles: ProductImageFile[] | ProductPdfFile[];
  dataId: number;
  productVariationId?: number;
  productId?: number;
  metadataId?: number;
  maxFiles?: number;
  type?: string;
  uploading?: (isUploading: boolean) => void;
  dataType?: keyof typeof DataType;
}) {
  const isDefinedAtProduct = dataType === DataType.PRODUCT && !productId;
  const [createMetadataFile, { loading: uploadingFile }] = useMutation(
    CREATE_PRODUCT_OR_VARIATION_METADATA_FILE,
    {
      refetchQueries: 'active',
    }
  );
  const [createMetadataPhoto, { loading: uploadingImage }] = useMutation(
    CREATE_PRODUCT_OR_VARIATION_METADATA_PHOTO,
    {
      refetchQueries: 'active',
    }
  );

  const [deleteMetadataFile] = useMutation(
    DELETE_PRODUCT_OR_VARIATION_METADATA_FILE,
    {
      refetchQueries: 'active',
    }
  );

  useEffect(() => {
    if (uploading) {
      if (uploadingFile || uploadingImage) {
        uploading(true);
        return;
      }
      uploading(false);
    }
  }, [uploadingFile, uploadingImage, uploading]);

  const uploadHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];
      await resolveMutation(
        createMetadataPhoto({
          variables: {
            data: {
              id: dataId,
              file,
              metadataId,
              productVariationId,
              productId,
            },
          },
        }),
        {
          successMessage: 'Image uploaded',
        }
      );
    } else {
      errorAlert('There was no file uploaded');
    }
  };

  const uploadFileHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];
      await resolveMutation(
        createMetadataFile({
          variables: {
            data: {
              id: dataId,
              file,
              metadataId,
              productId,
              productVariationId,
            },
          },
        }),
        {
          successMessage: 'File uploaded',
        }
      );
    } else {
      errorAlert('There was no file uploaded');
    }
  };

  const deleteHandler = async (fileId: number) => {
    await resolveMutation(
      deleteMetadataFile({
        variables: {
          data: {
            id: fileId,
            productId: productId,
            productVariationId: productVariationId,
            dataId: dataId,
          },
        },
      }),
      {
        successMessage: 'File removed',
      }
    );
  };
  return (
    <div className="relative rounded-lg bg-white flex flex-col space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500">
      {title && <p className="text-sm font-medium text-gray-900">{title}</p>}
      {existingFiles &&
        existingFiles.map((image, index) => (
          <div key={index} className="border-b flex justify-between">
            <div className="m-1 relative w-24 h-24">
              {type === 'image' && 'uri' in image && image.uri ? (
                <Image
                  src={`${imageFromS3Key(image.uri, 'small')}`}
                  alt={`${image.name}`}
                  fill
                  className="h-full w-full object-contain object-center"
                />
              ) : (
                <DocumentTextIcon className="h-10 w-10" />
              )}
            </div>
            <div>
              <Text>{image.name}</Text>
            </div>
            <div className="flex items-center">
              <Button
                type="button"
                size="xs"
                color={isDefinedAtProduct ? 'disabled' : 'danger'}
                disabled={isDefinedAtProduct}
                onClick={async () => {
                  if (image.id) {
                    await deleteHandler(image.id);
                  }
                }}
              >
                <MinusCircleIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                {!isDefinedAtProduct ? 'Remove' : 'Set at product level'}
              </Button>
            </div>
          </div>
        ))}

      <div className="flex justify-end">
        {maxFiles && existingFiles && existingFiles.length >= maxFiles ? (
          <p className="text-sm font-medium text-gray-900">
            {maxFiles ?? maxFiles} files uploaded
          </p>
        ) : (
          name && (
            <Upload
              name={name}
              uploadHandler={
                type === 'image' ? uploadHandler : uploadFileHandler
              }
              accept={type === 'image' ? 'image/*' : '.pdf'}
              loading={uploadingFile || uploadingImage}
              label={type === 'image' ? 'Upload an image' : 'Upload a file'}
            />
          )
        )}
      </div>
    </div>
  );
}

export default ProductFileInput;
